import request from "@/utils/request";

// 查询商品规格列表
export function goodsSpecList(query) {
  return request({
    url: "/mall/goodsSpec/list",
    method: "get",
    params: query,
  });
}

// 新增商品规格
export function goodsSpecAdd(data) {
  return request({
    url: "/mall/goodsSpec/add",
    method: "post",
    data: data,
  });
}

// 修改商品规格
export function goodsSpecEdit(data) {
  return request({
    url: "/mall/goodsSpec/edit",
    method: "post",
    data: data,
  });
}

export function goodsSpecDel(data) {
  return request({
    url: `/mall/goodsSpec/del/${data.specId}`,
    method: "delete",
  });
}
