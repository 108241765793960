import request from "@/utils/request";

// 运费模板列表
export function expressTemplateList(query) {
  return request({
    url: "/mall/expressTemplate/list",
    method: "get",
    params: query,
  });
}

// 编辑运费模板
export function expressTemplateEdit(data) {
  return request({
    url: "/mall/expressTemplate/edit",
    method: "put",
    data: data,
  });
}

// 新增运费模板
export function expressTemplateAdd(data) {
  return request({
    url: "/mall/expressTemplate/add",
    method: "post",
    data: data,
  });
}

// 删除运费模板
export function expressTemplateDelete(templateId) {
  return request({
    url: `/mall/expressTemplate/del/${templateId}`,
    method: "delete",
  });
}
