<template>
  <div class="app-container">
    <el-form ref="addForm" :rules="addFormRules" :model="addForm" size="small">
      <el-form-item label="商品名称" prop="goodsName" label-width="120px">
        <el-input
          style="width: 200px"
          v-model="addForm.goodsName"
          placeholder="请输入商品名称"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="商品分类" prop="categoryIds" label-width="120px">
        <el-cascader
          style="width: 200px"
          v-model="addForm.categoryIds"
          :options="categoryList"
          @change="handleChange"
          clearable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="商品封面图" prop="cover" label-width="120px">
        <ImageUpload
          :limit="1"
          :isShowTip="true"
          @input="handleCoverUpload"
          :value="addForm.cover"
        />
      </el-form-item>
      <el-form-item label="商品轮播图" prop="bannerImgs" label-width="120px">
        <ImageUpload
          :limit="5"
          :isShowTip="true"
          @input="handleBannerImgsUpload"
          :value="addForm.bannerImgs"
        />
      </el-form-item>
      <el-form-item
        label="商品详情图"
        prop="detailImgs"
        label-width="100px"
        :value="addForm.detailImgs"
      >
        <ImageUpload
          :limit="5"
          :isShowTip="true"
          @input="handleDetailImgsUpload"
        />
      </el-form-item>
      <el-form-item label="运费" label-width="120px" prop="expressTemplateId">
        <el-select v-model="addForm.expressTemplateId" placeholder="请选择">
          <el-option
            v-for="item in expressTemplateLists"
            :key="item.templateId"
            :label="item.templateName"
            :value="item.templateId"
          >
          </el-option>
        </el-select>
        <span
          style="color: #606266; padding-left: 10px"
          v-if="currentTemplate.feeType === '1'"
          >运费：{{ currentTemplate.feeAmount || 0 }}元包邮</span
        >
        <span
          style="color: #606266; padding-left: 10px"
          v-if="currentTemplate.feeType === '2'"
          >运费：满{{ currentTemplate.feeLimit }}元包邮，未达{{
            currentTemplate.feeLimit
          }}元运费为{{ currentTemplate.feeAmount }}元</span
        >
        <span
          style="color: #606266; padding-left: 10px"
          v-if="currentTemplate.feeType === '3'"
          >运费：{{ currentTemplate.feeAmount }}元</span
        >
      </el-form-item>
      <el-form-item label="商品规格" label-width="120px" prop="specType">
        <el-radio-group v-model="addForm.specType" @change="specTypeChange">
          <el-radio label="1">单规格</el-radio>
          <el-radio label="2">多规格</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        label="选择规格模板"
        label-width="120px"
        prop="spec.specId"
        v-if="addForm.specType == 2"
      >
        <el-col>
          <el-select
            @change="handleChangeSpec"
            v-model="addForm.spec.specId"
            placeholder="请选择"
          >
            <el-option
              v-for="item in specsList"
              :key="item.specId"
              :label="item.specName"
              :value="item.specId"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col style="margin-top: 12px">
          <el-button type="primary" @click="handleAddSpecs"
            >添加新规格</el-button
          >
        </el-col>
      </el-form-item>
      <div style="display: flex" v-if="addSpecs && addForm.specType == 2">
        <el-form-item label="规格" label-width="100px">
          <el-input
            style="width: 200px"
            v-model="specObj.name"
            placeholder="请输入规格"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="规格值" label-width="100px">
          <el-input
            style="width: 200px"
            v-model="specObj.values[0]"
            placeholder="请输入规格值"
            clearable
          ></el-input>
          <el-button
            style="margin-left: 12px"
            type="primary"
            @click="handleSureAddSpec"
            >确定</el-button
          >
          <el-button type="default" @click="handleCancelAddSpec"
            >取消</el-button
          >
        </el-form-item>
      </div>
      <el-form-item label-width="100px" v-if="addForm.specType == 2">
        <div v-for="(item, index) in specs" :key="index">
          <div>
            <el-tag closable @close="removeTag(index)">{{ item.name }}</el-tag>
          </div>
          <div style="display: flex">
            <div style="display: flex; align-items: center">
              <div v-for="(v, k) in item.values" :key="k">
                <el-tag
                  @close="removeSecondTag(index, k)"
                  v-if="v"
                  size="medium"
                  style="margin-right: 8px"
                  :closable="item.values.length > 1"
                  >{{ v }}</el-tag
                >
              </div>
            </div>
            <div style="display: flex; align-items: center">
              <!-- <el-input
                v-if="addSecondSpecs"
                v-model="specs[index].value[specs[index].value.length - 1]"
              ></el-input> -->
              <el-input
                style="margin-right: 12px"
                @blur="handleAddSecondSpecBlur($event, index)"
                v-if="item.addSecondSpecs"
                v-model="secondSpecsValue"
              ></el-input>
              <el-button type="primary" @click="handleAddSecondSpec(index)">
                添加
              </el-button>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item
        label-width="100px"
        label="商品属性"
        :required="true"
        v-if="customHeader.length > 0"
      >
        <!-- <el-table
          :data="tableData"
          :span-method="objectSpanMethod"
          border
          style="width: 100%; margin-top: 20px"
        >
          <el-table-column prop="id" label="ID" width="180"></el-table-column>
          <blockquote v-for="(item, index) in customHeader" :key="index">
            {{ item }}
            <el-table-column :prop="item.prop" :label="item.label">
            </el-table-column>
          </blockquote>
        </el-table> -->
        <el-form ref="specsForm" :model="{ tableDataRows }" inline-message>
          <el-table
            ref="specsTable"
            border
            :data="tableDataRows"
            :span-method="objectSpanMethod"
            :key="tableKey"
          >
            <el-table-column
              v-for="(item, index) in customHeader"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              v-if="
                item.label != 'originalPrice' &&
                item.label != 'salePrice' &&
                item.label != 'costPrice' &&
                item.label != 'stock' &&
                item.label != 'disabled'
              "
            >
            </el-table-column>
            <el-table-column label="图片">
              <template slot-scope="scope">
                <el-form-item
                  :prop="`tableDataRows.${scope.$index}.image`"
                  :rules="{
                    required: true,
                    message: '请上传图片',
                    trigger: 'blur',
                  }"
                >
                  <ImageUpload
                    :disabled="scope.row.disabled"
                    :limit="1"
                    :isShowTip="false"
                    @input="handleUpload($event, scope.$index)"
                    :value="scope.row.image"
                /></el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="原价" prop="originalPrice">
              <template slot-scope="scope">
                <el-form-item
                  :prop="`tableDataRows.${scope.$index}.originalPrice`"
                  :rules="{
                    required: true,
                    message: '请输入原价',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    :disabled="scope.row.disabled"
                    v-model="scope.row.originalPrice"
                    @blur="priceInput($event, scope.$index, 'originalPrice')"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="售价">
              <template slot-scope="scope">
                <el-form-item
                  :prop="`tableDataRows.${scope.$index}.salePrice`"
                  :rules="{
                    required: true,
                    message: '请输入售价',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    :disabled="scope.row.disabled"
                    v-model="scope.row.salePrice"
                    @blur="priceInput($event, scope.$index, 'salePrice')"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="成本价">
              <template slot-scope="scope">
                <el-form-item
                  :prop="`tableDataRows.${scope.$index}.costPrice`"
                  :rules="{
                    required: true,
                    message: '请输入成本价',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    :disabled="scope.row.disabled"
                    v-model="scope.row.costPrice"
                    @blur="priceInput($event, scope.$index, 'costPrice')"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="库存" prop="stock">
              <template slot-scope="scope">
                <el-form-item
                  :prop="`tableDataRows.${scope.$index}.stock`"
                  :rules="{
                    required: true,
                    message: '请输入库存',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    :disabled="scope.row.disabled"
                    v-model="scope.row.stock"
                    @blur="priceInput($event, scope.$index, 'stock')"
                  />
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="deleteSpec(scope.row, scope.$index)"
                  >{{ scope.row.disabled ? "启用" : "禁用" }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </el-form-item>
      <el-form-item label="是否上架" prop="enabled">
        <el-switch
          class="main-switch"
          :width="60"
          :value="addForm.enabled"
          active-value="1"
          inactive-value="0"
          inactive-text="下架"
          active-text="上架"
          readonly
          @click.native="switchChange"
        >
        </el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="onCancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { goodsCategoryList } from "@/api/shoppingmall/commodityclassify";
import { expressTemplateList } from "@/api/logistics/freightTemplate";
import { goodsAdd } from "@/api/shoppingmall/commoditymanage";
import { goodsSpecList } from "@/api/shoppingmall/commodityspec";
import ImageUpload from "@/components/ImageUpload/newUpload";
export default {
  components: { ImageUpload },
  data() {
    return {
      specsFormRules: {},
      categoryList: [],

      checkTableData: [],
      mergeObj: {}, // 用来记录需要合并行的下标

      // tableData: [],
      radio: "3",
      addForm: {
        specType: "1",
        spec: {
          specName: "",
          specId: "",
        },
        enabled: "1",
      },
      specObj: {
        name: "",
        values: [],
      },

      addFormRules: {
        // 根据addFormRules里面的属性值帮我写完校验规则
        goodsName: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
        ],
        categoryIds: [
          { required: true, message: "请选择商品分类", trigger: "change" },
        ],
        cover: [
          { required: true, message: "请上传商品封面", trigger: "change" },
        ],
        bannerImgs: [
          { required: true, message: "请上传轮播图", trigger: "change" },
        ],
        detailImgs: [
          { required: true, message: "请上传商品详情图", trigger: "change" },
        ],
        expressTemplateId: [
          { required: true, message: "请选择运费模板", trigger: "change" },
        ],
        specType: [
          { required: true, message: "请选择商品规格", trigger: "change" },
        ],
        "spec.specId": [
          { required: true, message: "请选择规格模板", trigger: "change" },
        ],
      },

      value: "",
      templateOptions: [
        { value: "0", label: "无规格" },
        { value: "1", label: "有规格" },
      ],
      templateOptoons: "",
      feeOptions: [
        { value: "0", label: "包邮" },
        { value: "1", label: "不包邮" },
      ],
      uniqueKeysArray: [],
      mergeObj: {}, // 用来记录需要合并行的下标
      specs: [{ name: "规格", values: ["默认"] }], // 记录添加的规格
      addSpecs: false,
      // addSecondSpecs: false,
      secondSpecsValue: "",
      customHeader: [],
      tableDataRows: [],
      expressTemplateLists: [], //运费模板
      specsForm: {},
      specsList: [],
      tableKey: 0, //用于强制表格重新渲染
      isFirstAddImage: true,
      currentTemplate: {},
    };
  },
  computed: {
    tableData() {
      console.log("checkTableData", this.checkTableData, this.uniqueKeysArray);
      let list = [...this.checkTableData];
      this.mergeObj = {}; // 清空之前的合并信息

      // 定义需要合并的列
      const columnsToMerge = this.customHeader.map((item) => item.prop);

      columnsToMerge.forEach((column) => {
        let count = 0; // 用来记录需要合并行的起始位置
        this.mergeObj[column] = []; // 记录每一列的合并信息

        list.forEach((item, index) => {
          item.disabled = false; // 默认所有行都显示

          // index == 0表示数据为第一行，直接 push 一个 1
          if (index === 0) {
            // item[`${column}Index`] = 1;
            this.mergeObj[column].push(1);
          } else {
            // 判断当前行是否与上一行其值相等 如果相等 在 count 记录的位置其值 +1 表示当前行需要合并 并push 一个 0 作为占位
            if (item[column] === list[index - 1][column]) {
              this.mergeObj[column][count] += 1;
              this.mergeObj[column].push(0);
              // item[`${column}Index`] = this.mergeObj[column][count];
            } else {
              // 如果当前行和上一行其值不相等
              count = index; // 记录当前位置
              // item[`${column}Index`] = 1;
              this.mergeObj[column].push(1); // 重新push 一个 1
            }
          }
        });
      });

      console.log("tableData1111", list, this.mergeObj);

      return list;
    },
  },
  watch: {
    "addForm.expressTemplateId": function (val) {
      this.currentTemplate = this.expressTemplateLists.find(
        (v) => v.templateId === val
      );
      console.log("currentTemplate", this.currentTemplate);
    },
    tableData(val) {
      val = val.map((v) => {
        return {
          ...v,
          isFirstAddoriginalPrice: true,
          isFirstAddsalePrice: true,
          isFirstAddcostPrice: true,
          isFirstAddstock: true,
        };
      });
      console.log("歪扭", val);

      this.tableDataRows = JSON.parse(JSON.stringify(val || []));
    },
  },
  created() {
    this.getGoodsCategoryList();
    this.getExpressTemplateList();
    this.getGoodsSpecList();
    // let colorArr = { name: "颜色", value: ["红色", "蓝色"] };
    // let sizeArr = { name: "尺码", value: ["大码", "中码", "小码"] };
    // let weightArr = { name: "重量", value: ["1斤", "2斤"] };
    // // 示例规格对象数组
    // this.specs = [
    //   colorArr,
    //   sizeArr,
    //   weightArr,
    //   // 可以根据需要添加更多规格对象
    // ];
    if (this.addForm.specType == 1) {
      console.log("走进来了吗");
      console.log("specs", this.specs);

      this.commonFormatData();
    }
  },
  methods: {
    switchChange() {
      if (this.addForm.enabled === "1") {
        this.addForm.enabled = "0";
      } else {
        this.addForm.enabled = "1";
      }
    },
    getGoodsSpecList() {
      goodsSpecList().then((res) => {
        this.specsList = res.data;
      });
    },
    // 选择规格模板
    handleChangeSpec(e) {
      this.addForm.spec = this.specsList.find((v) => v.specId == e);
      this.specs = this.specsList.find((v) => v.specId == e).configs;
      this.commonFormatData();
    },
    handleCoverUpload(e) {
      let imgs = e
        .map((v) => {
          return v.url;
        })
        .join();
      this.$set(this.addForm, "cover", imgs);
      this.$nextTick(() => {
        this.$refs.addForm.validateField("cover");
      });
    },
    handleBannerImgsUpload(e) {
      console.log("e", e);
      let imgs = e.map((v) => {
        return v.url;
      });
      this.$set(this.addForm, "bannerImgs", imgs);
      this.$nextTick(() => {
        this.$refs.addForm.validateField("bannerImgs");
      });
    },
    handleDetailImgsUpload(e) {
      let imgs = e.map((v) => {
        return v.url;
      });
      this.$set(this.addForm, "detailImgs", imgs);
      this.$nextTick(() => {
        this.$refs.addForm.validateField("detailImgs");
      });
    },
    // 校验单价
    // checkPrice(rule, value, callback) {
    //   console.log("rule", rule, value);

    //   if (value === "") {
    //     callback(new Error("请输入单价"));
    //   } else {
    //     callback();
    //   }
    // },
    // 输入金额正则
    priceInput(e, index, type) {
      console.log("type", e, index, type);

      let val = e.target.value

        .replace(/[^\d.]/g, "")
        .replace(/\.{2,}/g, ".")
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".")
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3")
        .replace(/^\./g, "")
        .replace(/^0\d/, "0");
      console.log(
        "tableDataRows",
        this.tableDataRows,
        this.tableDataRows[index]["isFirstAdd" + type]
      );

      // this.tableDataRows 所有的 originalPrice 都赋值 val
      if (this.tableDataRows[index]["isFirstAdd" + type]) {
        this.tableDataRows = this.tableDataRows.map((v) => {
          return {
            ...v,
            [type]: val,
            ["isFirstAdd" + type]: false,
          };
        });
        this.$set(this.tableDataRows[index], type, val);
      }

      console.log("tableDataRows", this.tableDataRows);
    },
    handleUpload(data, index) {
      console.log("data", data);
      if (data.length == 0) {
        // 删除 位置 index的图片
        this.tableDataRows.forEach((v, k) => {
          if (index == k) {
            v.image = "";
          }
        });
      } else {
        if (this.isFirstAddImage) {
          let imgs = data
            .map((v) => {
              return v.url;
            })
            .join();

          // this.$set(this.checkCourseList[index], `${str}`, imgs);
          // console.log("77788", this.checkCourseList);
          // this.tableDataRows 每一行的图片都赋值 imgs
          this.tableDataRows = this.tableDataRows.map((v) => {
            return {
              ...v,
              image: imgs,
            };
          });
          this.isFirstAddImage = false;
        }

        console.log("this.tableDataRows", this.tableDataRows);
      }

      // this.tableDataRows[index]["image"] = imgs;
      this.$nextTick(() => {
        this.$refs.specsForm.validateField(`tableDataRows.${index}.image`);
      });
      this.$forceUpdate();
    },
    specTypeChange(e) {
      console.log("e", e);
      if (e == 1) {
        this.specs = [{ name: "规格", values: ["默认"] }];
        this.commonFormatData();
      } else {
        this.specs = [];
        this.checkTableData = [];
        this.customHeader = [];
      }
    },
    getExpressTemplateList() {
      expressTemplateList().then((res) => {
        this.expressTemplateLists = res.data;
      });
    },
    // handleCoverUpload(e) {
    //   console.log("e", e);
    // },
    // handleBannerImgsUpload(e) {
    //   console.log("e", e);
    // },
    // handleDetailImgsUpload(e) {
    //   console.log("e", e);
    // },
    // 商品分类的列表
    getGoodsCategoryList() {
      goodsCategoryList().then((res) => {
        this.categoryList = res.data;
        this.categoryList.forEach((item) => {
          item.children = item.childList;
          item.label = item.name;
          item.value = item.categoryId;
          item.children &&
            item.children.forEach((child) => {
              child.label = child.name;
              child.value = child.categoryId;
            });
        });
        console.log("categoryList", this.categoryList);
      });
    },
    onCancel() {
      // 返回上一页
      this.$router.go(-1);
    },
    onSubmit() {
      console.log("提交this.addForm", this.addForm, this.tableDataRows);

      console.log("提交this.form", this.form);
      let p1 = new Promise((resolve, reject) => {
        this.$refs["addForm"].validate((valid, obj) => {
          if (valid) {
            resolve();
          } else {
            reject(obj);
          }
        });
      });
      let p2 = new Promise((resolve, reject) => {
        this.$refs["specsForm"].validate((valid, obj) => {
          if (valid) {
            resolve();
          } else {
            reject(obj);
          }
        });
      });
      Promise.all([p1, p2])
        .then((res) => {
          console.log("res", res);
          let data = {
            ...this.addForm,
            bannerImgs: this.addForm.bannerImgs.map((v) => {
              return {
                url: v,
              };
            }),
            detailImgs: this.addForm.detailImgs.map((v) => {
              return {
                url: v,
              };
            }),
            // enabled: 1,
            categoryIds: this.addForm.categoryIds.join(","),
            specConfigs: this.tableDataRows,
          };
          goodsAdd(data).then((res) => {
            console.log("res", res);
            this.$router.go(-1);
            this.$message.success("提交成功");
          });
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // 删除规格
    deleteSpec(row, index) {
      console.log("row", row, index);

      this.tableDataRows[index].disabled = !this.tableDataRows[index].disabled;
      this.$forceUpdate();
      // this.checkTableData.splice(index, 1);
    },

    // commonFormatData() {
    //   console.log("this.specs", this.specs);

    //   const specsArray = this.generateCombinations(this.specs);
    //   const newCheckTableData = [];

    //   // 保留已有的数据
    //   specsArray.forEach((newSpec) => {
    //     const existingRow = this.tableDataRows.find((row) =>
    //       Object.keys(newSpec).every((key) => row[key] === newSpec[key])
    //     );
    //     if (existingRow) {
    //       newCheckTableData.push(existingRow);
    //     } else {
    //       newCheckTableData.push(newSpec);
    //     }
    //   });
    //   console.log(
    //     "specsArray-specsArray-specsArray",
    //     specsArray,
    //     newCheckTableData
    //   );

    //   this.checkTableData = [];
    //   this.tableDataRows = [];

    //   this.checkTableData = newCheckTableData;
    //   this.tableDataRows = JSON.parse(JSON.stringify(newCheckTableData));

    //   // this.checkTableData = newCheckTableData;
    //   // console.log("checkTableData11111", this.checkTableData);

    //   this.uniqueKeysArray = this.getUniqueKeys(this.checkTableData);

    //   console.log(
    //     "uniqueKeysArray-uniqueKeysArray-uniqueKeysArray",
    //     this.uniqueKeysArray
    //   );

    //   this.uniqueKeysArray = this.uniqueKeysArray.filter((v) => {
    //     return (
    //       v != "originalPrice" &&
    //       v != "salePrice" &&
    //       v != "costPrice" &&
    //       v != "stock" &&
    //       v != "disabled"
    //     );
    //   });
    //   this.getCustomHeader();
    //   // 重新计算合并逻辑
    //   this.$nextTick(() => {
    //     this.tableKey += 1;
    //   });
    // },

    commonFormatData() {
      console.log("this.specs", this.specs);
      console.log("this.tableDataRows", this.tableDataRows);

      const specsArray = this.generateCombinations(this.specs);
      console.log("specsArray", specsArray);

      const newCheckTableData = [];

      // 保留已有的数据
      this.tableDataRows.forEach((row) => {
        newCheckTableData.push(row);
      });

      // 添加新的组合
      specsArray.forEach((newSpec) => {
        // 查找是否有相同的规格组合
        const existingRow = newCheckTableData.find((row) =>
          Object.keys(newSpec).every((key) => row[key] === newSpec[key])
        );

        if (existingRow) {
          // 如果已经存在，合并新的数据字段
          Object.keys(newSpec).forEach((key) => {
            if (!existingRow.hasOwnProperty(key)) {
              existingRow[key] = newSpec[key];
            }
          });
        } else {
          // 如果不存在，创建一个新的行，保留已有的数据字段
          const newRow = { ...newSpec };

          // 查找是否有部分匹配的行
          const partialMatchRow = newCheckTableData.find((row) =>
            Object.keys(newSpec).every(
              (key) => !row.hasOwnProperty(key) || row[key] === newSpec[key]
            )
          );

          if (partialMatchRow) {
            // 如果找到部分匹配的行，合并新的数据字段
            Object.keys(newSpec).forEach((key) => {
              if (!partialMatchRow.hasOwnProperty(key)) {
                partialMatchRow[key] = newSpec[key];
              }
            });
          } else {
            // 如果没有找到匹配的行，添加新的行
            newCheckTableData.push(newRow);
          }
        }
      });

      console.log(
        "newCheckTableData-newCheckTableData-newCheckTableData",
        newCheckTableData
      );

      this.checkTableData = newCheckTableData;
      this.tableDataRows = JSON.parse(JSON.stringify(newCheckTableData));

      this.uniqueKeysArray = this.getUniqueKeys(this.checkTableData);

      console.log(
        "uniqueKeysArray-uniqueKeysArray-uniqueKeysArray",
        this.uniqueKeysArray
      );

      this.uniqueKeysArray = this.uniqueKeysArray.filter((v) => {
        return (
          v != "originalPrice" &&
          v != "salePrice" &&
          v != "costPrice" &&
          v != "stock" &&
          v != "disabled" &&
          v != "isFirstAddoriginalPrice" &&
          v != "isFirstAddsalePrice" &&
          v != "isFirstAddcostPrice" &&
          v != "isFirstAddstock"
        );
      });
      this.getCustomHeader();

      // 重新计算合并逻辑
      this.$nextTick(() => {
        this.tableKey += 1;
      });
    },

    // removeTag(index) {
    //   this.specs.splice(index, 1);
    //   if (this.specs.length === 0) {
    //     this.customHeader = [];
    //     this.checkTableData = [];
    //     this.tableDataRows = [];
    //   } else {
    //     this.commonFormatData();
    //   }
    //   // 强制表格重新渲染
    //   this.tableKey += 1;
    //   this.$forceUpdate();
    // },
    removeTag(index) {
      const specName = this.specs[index].name;

      // 从 specs 中移除规格
      this.specs.splice(index, 1);

      // 如果 specs 为空，则清空相关数据
      if (this.specs.length === 0) {
        this.customHeader = [];
        this.checkTableData = [];
        this.tableDataRows = [];
      } else {
        // 过滤掉包含被删除规格的数据
        this.checkTableData = this.checkTableData.filter(
          (item) => !item.hasOwnProperty(specName)
        );
        this.tableDataRows = this.tableDataRows.filter(
          (item) => !item.hasOwnProperty(specName)
        );

        // 重新生成 checkTableData 和 tableDataRows
        this.commonFormatData();
      }

      // 强制表格重新渲染
      this.tableKey += 1;
    },

    removeSecondTag(index, k) {
      console.log(
        "this.specs[index].values",
        this.specs[index].name,
        this.specs[index].values[k]
      );
      console.log(this.tableDataRows, this.checkTableData);

      const specName = this.specs[index].name;
      const specValue = this.specs[index].values[k];

      // 更新 tableDataRows
      this.tableDataRows = this.tableDataRows.filter(
        (item) => item[specName] !== specValue
      );

      // 更新 checkTableData
      this.checkTableData = this.checkTableData.filter(
        (item) => item[specName] !== specValue
      );

      console.log(this.tableDataRows, this.checkTableData);

      // 从 specs 中移除规格值
      this.specs[index].values.splice(k, 1);

      // 重新计算合并逻辑
      this.commonFormatData();

      // 强制表格重新渲染
      this.tableKey += 1;
    },

    // removeSecondTag(index, k) {
    //   console.log(
    //     "this.specs[index].values",
    //     this.specs[index].name,
    //     this.specs[index].values[k]
    //   );
    //   console.log(this.tableDataRows, this.checkTableData);

    //   const specName = this.specs[index].name;
    //   const specValue = this.specs[index].values[k];

    //   // 更新 tableDataRows
    //   this.tableDataRows = this.tableDataRows.filter(
    //     (item) => item[specName] !== specValue
    //   );

    //   // 更新 checkTableData
    //   this.checkTableData = this.checkTableData.filter(
    //     (item) => item[specName] !== specValue
    //   );

    //   console.log(this.tableDataRows, this.checkTableData);

    //   // 从 specs 中移除规格值
    //   this.specs[index].values.splice(k, 1);

    //   // 重新计算合并逻辑
    //   this.commonFormatData();

    //   // 强制表格重新渲染
    //   this.tableKey += 1;
    // },

    handleAddSecondSpecBlur(e, index) {
      this.specs[index].values.push("");
      this.specs[index].values[this.specs[index].values.length - 1] =
        e.target.value;
      this.specs[index].addSecondSpecs = false;
      this.secondSpecsValue = "";

      console.log("this.specs[index].values", this.specs);

      this.commonFormatData();
      this.$forceUpdate();
      // this.secondSpecsValue = "";
    },
    handleAddSecondSpec(index) {
      this.specs[index].addSecondSpecs = true;
      this.$forceUpdate();
    },
    handleSureAddSpec() {
      console.log("this.specObj", this.specObj);

      if (this.specObj.name === "") {
        this.$message.error("规格名不能为空");
        return;
      }
      if (this.specObj.values[0] == "" || this.specObj.values.length === 0) {
        this.$message.error("规格值不能为空");
        return;
      }
      this.addSpecs = false;
      this.specs.push(JSON.parse(JSON.stringify(this.specObj)));
      this.specObj = { name: "", values: [], addSecondSpecs: false };
      console.log("this.specs", this.specs);
      this.commonFormatData();
    },
    handleCancelAddSpec() {
      this.specObj = { name: "", values: [], addSecondSpecs: false };
    },
    // 添加新规格
    handleAddSpecs() {
      this.addSpecs = true;
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // 判断列的属性是否在需要合并的列中
      if (this.uniqueKeysArray.includes(column.property)) {
        // 判断其值是不是为0
        if (
          this.mergeObj[column.property] &&
          this.mergeObj[column.property][rowIndex]
        ) {
          return [this.mergeObj[column.property][rowIndex], 1];
        } else {
          // 如果为0则为需要合并的行
          return [0, 0];
        }
      }
      return [1, 1]; // 默认情况下不合并
    },

    getCustomHeader() {
      console.log("uniqueKeysArray", this.uniqueKeysArray);

      this.customHeader = this.uniqueKeysArray.map((item) => {
        return {
          label: item,
          prop: item,
        };
      });
      console.log("customHeader", this.customHeader);
    },
    /**
     * 从对象数组中提取唯一键
     * @param {Array} arr - 包含对象的数组
     * @returns {Array} - 包含唯一键的数组
     */
    getUniqueKeys(arr) {
      const uniqueKeys = new Set();

      arr.forEach((obj) => {
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            uniqueKeys.add(key);
          }
        }
      });

      return Array.from(uniqueKeys);
    },
    handleChange() {},
    // generateCombinations(specs) {
    //   // 递归函数，用于生成组合
    //   function combine(index, currentCombo) {
    //     // 如果已经处理完所有规格，则添加当前组合到结果中
    //     if (index === specs.length) {
    //       combinations.push(Object.assign({}, currentCombo));
    //       return;
    //     }

    //     // 获取当前规格的名称和选项
    //     const { name, values } = specs[index];

    //     // 遍历当前规格的所有选项
    //     for (const option of values) {
    //       // 将当前选项添加到组合中
    //       currentCombo[name] = option;
    //       // 递归处理下一个规格
    //       combine(index + 1, currentCombo);
    //     }
    //   }

    //   // 初始化一个空数组，用于存储所有规格组合
    //   const combinations = [];

    //   // 从第一个规格开始生成组合
    //   combine(0, {});

    //   // 返回包含所有规格组合的对象数组
    //   return combinations;
    // },

    generateCombinations(specs) {
      // 递归函数，用于生成组合
      function combine(index, currentCombo, result) {
        // 如果已经处理完所有规格，则添加当前组合到结果中
        if (index === specs.length) {
          combinations.push(Object.assign({}, currentCombo));
          return;
        }

        // 获取当前规格的名称和选项
        const { name, values } = specs[index];

        // 遍历当前规格的所有选项
        for (const option of values) {
          // 将当前选项添加到组合中
          currentCombo[name] = option;
          // 递归处理下一个规格
          combine(index + 1, currentCombo, result);
        }
      }

      // 初始化一个空数组，用于存储所有规格组合
      const combinations = [];

      // 从第一个规格开始生成组合
      combine(0, {}, combinations);

      // 返回包含所有规格组合的对象数组
      return combinations;
    },
  },
};
</script>

<style lang="scss">
.el-picker-panel.date_picker {
  .el-button--text.el-picker-panel__link-btn {
    display: none;
  }
}
.main-switch {
  position: relative;
}
.main-switch .el-switch__core {
  height: 24px;
  border-radius: 12px;
}
.main-switch .el-switch__core:after {
  left: 4px;
}
.main-switch.el-switch.is-checked .el-switch__core::after {
  margin-left: -20px;
}
.main-switch .el-switch__core:after {
  top: 3px;
}
.main-switch .el-switch__label {
  position: absolute;
  top: 0;
}
.main-switch .el-switch__label--left {
  right: 0;
  color: #999;
  z-index: 1;
  margin-right: 8px;
}
.main-switch .el-switch__label--right {
  left: 0;
  color: #fff;
  opacity: 0;
  margin-left: 8px;
}
.main-switch.is-checked .el-switch__label--left {
  opacity: 0;
}
.main-switch.is-checked .el-switch__label--right {
  opacity: 1;
}
</style>
