import request from "@/utils/request";

// 商品列表
export function goodsList(query) {
  return request({
    url: "/mall/goods/list",
    method: "get",
    params: query,
  });
}

// 调整排序
export function goodsSort(data) {
  return request({
    url: "/mall/goods/sort",
    method: "put",
    data,
  });
}

// 上架下架
export function goodsSwitchEnabled(data) {
  return request({
    url: "/mall/goods/switchEnabled",
    method: "put",
    data,
  });
}

// 删除商品

export function goodsDelete(goodsId) {
  return request({
    url: `/mall/goods/del/${goodsId}`,
    method: "put",
  });
}

// 添加商品
export function goodsAdd(data) {
  return request({
    url: `/mall/goods/add`,
    method: "post",
    data,
  });
}

// 商品详情

export function goodsInfo(goodsId) {
  return request({
    url: `/mall/goods/info/${goodsId}`,
    method: "get",
  });
}

// 修改商品

export function goodsEdit(data) {
  return request({
    url: `/mall/goods/edit`,
    method: "put",
    data,
  });
}

// 置顶
export function goodsTop(data) {
  return request({
    url: `/mall/goods/top?goodsId=${data.goodsId}`,
    method: "put",
    data,
  });
}
