var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "addForm",
          attrs: { rules: _vm.addFormRules, model: _vm.addForm, size: "small" },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: "商品名称",
                prop: "goodsName",
                "label-width": "120px",
              },
            },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: { placeholder: "请输入商品名称", clearable: "" },
                model: {
                  value: _vm.addForm.goodsName,
                  callback: function ($$v) {
                    _vm.$set(_vm.addForm, "goodsName", $$v)
                  },
                  expression: "addForm.goodsName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "商品分类",
                prop: "categoryIds",
                "label-width": "120px",
              },
            },
            [
              _c("el-cascader", {
                staticStyle: { width: "200px" },
                attrs: { options: _vm.categoryList, clearable: "" },
                on: { change: _vm.handleChange },
                model: {
                  value: _vm.addForm.categoryIds,
                  callback: function ($$v) {
                    _vm.$set(_vm.addForm, "categoryIds", $$v)
                  },
                  expression: "addForm.categoryIds",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "商品封面图",
                prop: "cover",
                "label-width": "120px",
              },
            },
            [
              _c("ImageUpload", {
                attrs: { limit: 1, isShowTip: true, value: _vm.addForm.cover },
                on: { input: _vm.handleCoverUpload },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "商品轮播图",
                prop: "bannerImgs",
                "label-width": "120px",
              },
            },
            [
              _c("ImageUpload", {
                attrs: {
                  limit: 5,
                  isShowTip: true,
                  value: _vm.addForm.bannerImgs,
                },
                on: { input: _vm.handleBannerImgsUpload },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "商品详情图",
                prop: "detailImgs",
                "label-width": "100px",
                value: _vm.addForm.detailImgs,
              },
            },
            [
              _c("ImageUpload", {
                attrs: { limit: 5, isShowTip: true },
                on: { input: _vm.handleDetailImgsUpload },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "运费",
                "label-width": "120px",
                prop: "expressTemplateId",
              },
            },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.addForm.expressTemplateId,
                    callback: function ($$v) {
                      _vm.$set(_vm.addForm, "expressTemplateId", $$v)
                    },
                    expression: "addForm.expressTemplateId",
                  },
                },
                _vm._l(_vm.expressTemplateLists, function (item) {
                  return _c("el-option", {
                    key: item.templateId,
                    attrs: { label: item.templateName, value: item.templateId },
                  })
                }),
                1
              ),
              _vm.currentTemplate.feeType === "1"
                ? _c(
                    "span",
                    {
                      staticStyle: { color: "#606266", "padding-left": "10px" },
                    },
                    [
                      _vm._v(
                        "运费：" +
                          _vm._s(_vm.currentTemplate.feeAmount || 0) +
                          "元包邮"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.currentTemplate.feeType === "2"
                ? _c(
                    "span",
                    {
                      staticStyle: { color: "#606266", "padding-left": "10px" },
                    },
                    [
                      _vm._v(
                        "运费：满" +
                          _vm._s(_vm.currentTemplate.feeLimit) +
                          "元包邮，未达" +
                          _vm._s(_vm.currentTemplate.feeLimit) +
                          "元运费为" +
                          _vm._s(_vm.currentTemplate.feeAmount) +
                          "元"
                      ),
                    ]
                  )
                : _vm._e(),
              _vm.currentTemplate.feeType === "3"
                ? _c(
                    "span",
                    {
                      staticStyle: { color: "#606266", "padding-left": "10px" },
                    },
                    [
                      _vm._v(
                        "运费：" + _vm._s(_vm.currentTemplate.feeAmount) + "元"
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: "商品规格",
                "label-width": "120px",
                prop: "specType",
              },
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.specTypeChange },
                  model: {
                    value: _vm.addForm.specType,
                    callback: function ($$v) {
                      _vm.$set(_vm.addForm, "specType", $$v)
                    },
                    expression: "addForm.specType",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: "1" } }, [_vm._v("单规格")]),
                  _c("el-radio", { attrs: { label: "2" } }, [_vm._v("多规格")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.addForm.specType == 2
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: "选择规格模板",
                    "label-width": "120px",
                    prop: "spec.specId",
                  },
                },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.handleChangeSpec },
                          model: {
                            value: _vm.addForm.spec.specId,
                            callback: function ($$v) {
                              _vm.$set(_vm.addForm.spec, "specId", $$v)
                            },
                            expression: "addForm.spec.specId",
                          },
                        },
                        _vm._l(_vm.specsList, function (item) {
                          return _c("el-option", {
                            key: item.specId,
                            attrs: { label: item.specName, value: item.specId },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { staticStyle: { "margin-top": "12px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleAddSpecs },
                        },
                        [_vm._v("添加新规格")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.addSpecs && _vm.addForm.specType == 2
            ? _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格", "label-width": "100px" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入规格", clearable: "" },
                        model: {
                          value: _vm.specObj.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.specObj, "name", $$v)
                          },
                          expression: "specObj.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "规格值", "label-width": "100px" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "请输入规格值", clearable: "" },
                        model: {
                          value: _vm.specObj.values[0],
                          callback: function ($$v) {
                            _vm.$set(_vm.specObj.values, 0, $$v)
                          },
                          expression: "specObj.values[0]",
                        },
                      }),
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-left": "12px" },
                          attrs: { type: "primary" },
                          on: { click: _vm.handleSureAddSpec },
                        },
                        [_vm._v("确定")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "default" },
                          on: { click: _vm.handleCancelAddSpec },
                        },
                        [_vm._v("取消")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.addForm.specType == 2
            ? _c(
                "el-form-item",
                { attrs: { "label-width": "100px" } },
                _vm._l(_vm.specs, function (item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      [
                        _c(
                          "el-tag",
                          {
                            attrs: { closable: "" },
                            on: {
                              close: function ($event) {
                                return _vm.removeTag(index)
                              },
                            },
                          },
                          [_vm._v(_vm._s(item.name))]
                        ),
                      ],
                      1
                    ),
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        _vm._l(item.values, function (v, k) {
                          return _c(
                            "div",
                            { key: k },
                            [
                              v
                                ? _c(
                                    "el-tag",
                                    {
                                      staticStyle: { "margin-right": "8px" },
                                      attrs: {
                                        size: "medium",
                                        closable: item.values.length > 1,
                                      },
                                      on: {
                                        close: function ($event) {
                                          return _vm.removeSecondTag(index, k)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(v))]
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          item.addSecondSpecs
                            ? _c("el-input", {
                                staticStyle: { "margin-right": "12px" },
                                on: {
                                  blur: function ($event) {
                                    return _vm.handleAddSecondSpecBlur(
                                      $event,
                                      index
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.secondSpecsValue,
                                  callback: function ($$v) {
                                    _vm.secondSpecsValue = $$v
                                  },
                                  expression: "secondSpecsValue",
                                },
                              })
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleAddSecondSpec(index)
                                },
                              },
                            },
                            [_vm._v(" 添加 ")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ])
                }),
                0
              )
            : _vm._e(),
          _vm.customHeader.length > 0
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    "label-width": "100px",
                    label: "商品属性",
                    required: true,
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "specsForm",
                      attrs: {
                        model: { tableDataRows: _vm.tableDataRows },
                        "inline-message": "",
                      },
                    },
                    [
                      _c(
                        "el-table",
                        {
                          key: _vm.tableKey,
                          ref: "specsTable",
                          attrs: {
                            border: "",
                            data: _vm.tableDataRows,
                            "span-method": _vm.objectSpanMethod,
                          },
                        },
                        [
                          _vm._l(_vm.customHeader, function (item, index) {
                            return item.label != "originalPrice" &&
                              item.label != "salePrice" &&
                              item.label != "costPrice" &&
                              item.label != "stock" &&
                              item.label != "disabled"
                              ? _c("el-table-column", {
                                  key: index,
                                  attrs: { prop: item.prop, label: item.label },
                                })
                              : _vm._e()
                          }),
                          _c("el-table-column", {
                            attrs: { label: "图片" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "tableDataRows." +
                                              scope.$index +
                                              ".image",
                                            rules: {
                                              required: true,
                                              message: "请上传图片",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("ImageUpload", {
                                            attrs: {
                                              disabled: scope.row.disabled,
                                              limit: 1,
                                              isShowTip: false,
                                              value: scope.row.image,
                                            },
                                            on: {
                                              input: function ($event) {
                                                return _vm.handleUpload(
                                                  $event,
                                                  scope.$index
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4116115797
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "原价", prop: "originalPrice" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "tableDataRows." +
                                              scope.$index +
                                              ".originalPrice",
                                            rules: {
                                              required: true,
                                              message: "请输入原价",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: scope.row.disabled,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.priceInput(
                                                  $event,
                                                  scope.$index,
                                                  "originalPrice"
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.originalPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "originalPrice",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "scope.row.originalPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              203583699
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "售价" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "tableDataRows." +
                                              scope.$index +
                                              ".salePrice",
                                            rules: {
                                              required: true,
                                              message: "请输入售价",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: scope.row.disabled,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.priceInput(
                                                  $event,
                                                  scope.$index,
                                                  "salePrice"
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.salePrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "salePrice",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.salePrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3851705088
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "成本价" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "tableDataRows." +
                                              scope.$index +
                                              ".costPrice",
                                            rules: {
                                              required: true,
                                              message: "请输入成本价",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: scope.row.disabled,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.priceInput(
                                                  $event,
                                                  scope.$index,
                                                  "costPrice"
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.costPrice,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "costPrice",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.costPrice",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3399529506
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "库存", prop: "stock" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-form-item",
                                        {
                                          attrs: {
                                            prop:
                                              "tableDataRows." +
                                              scope.$index +
                                              ".stock",
                                            rules: {
                                              required: true,
                                              message: "请输入库存",
                                              trigger: "blur",
                                            },
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              disabled: scope.row.disabled,
                                            },
                                            on: {
                                              blur: function ($event) {
                                                return _vm.priceInput(
                                                  $event,
                                                  scope.$index,
                                                  "stock"
                                                )
                                              },
                                            },
                                            model: {
                                              value: scope.row.stock,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  scope.row,
                                                  "stock",
                                                  $$v
                                                )
                                              },
                                              expression: "scope.row.stock",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              2307219524
                            ),
                          }),
                          _c("el-table-column", {
                            attrs: { label: "操作" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteSpec(
                                                scope.row,
                                                scope.$index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scope.row.disabled
                                                ? "启用"
                                                : "禁用"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3668268393
                            ),
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "是否上架", prop: "enabled" } },
            [
              _c("el-switch", {
                staticClass: "main-switch",
                attrs: {
                  width: 60,
                  value: _vm.addForm.enabled,
                  "active-value": "1",
                  "inactive-value": "0",
                  "inactive-text": "下架",
                  "active-text": "上架",
                  readonly: "",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.switchChange($event)
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }