import request from "@/utils/request";

// 查询商品分类列表
export function goodsCategoryList(query) {
  return request({
    url: "/mall/goodsCategory/list",
    method: "get",
    params: query,
  });
}

// 新增商品分类
export function goodsCategoryAdd(data) {
  return request({
    url: "/mall/goodsCategory/add",
    method: "post",
    data: data,
  });
}

//删除商品分类
export function goodsCategorydel(categoryId) {
  return request({
    url: `/mall/goodsCategory/del/${categoryId}`,
    method: "delete",
  });
}

// 修改商品分类
export function goodsCategoryEdit(data) {
  return request({
    url: "/mall/goodsCategory/edit",
    method: "post",
    data: data,
  });
}

// 显示隐藏分类
export function switchEnabled(data) {
  return request({
    url: "/mall/goodsCategory/switchEnabled",
    method: "put",
    data: data,
  });
}

// 调整排序
export function goodsCategorySort(data) {
  return request({
    url: "/mall/goodsCategory/sort",
    method: "put",
    data: data,
  });
}
